/* Dropdowns */

.dropdown {
    .dropdown-toggle {
        &:after {
            border-top: 0;
            border-right: 0;
            border-left: 0;
            border-bottom: 0;
            font: normal normal normal 24px/1 "Material Design Icons";
            content: "\f140";
            width: auto;
            height: auto;
            vertical-align: baseline;
            font-size: 14px;
        }
        &.toggle-arrow-hide {
            &:before {
                display: none;
            }
            &:after {
                display: none;
            }
        }
    }
    &:hover {
        .dropdown-toggle {
            +.dropdown-menu {
                display: block;
            }
        }
    }
    .dropdown-menu {
        margin-top: .75rem;
        font-size: $default-font-size;
        box-shadow: 0px 1px 15px 1px rgba(230, 234, 236, 0.35);
        border: 0 none;
        .dropdown-item {
            font-size: 1rem;
            padding: .25rem 1.5rem;
            cursor: pointer;
            &:active {
                background-color: #16a2f6;
                color: #fff;
            }
            &:hover {
                background-color: #16a2f6;
                color: #fff;
            }
        }
    }
}