.h2 {
    font-size: 1.5em;
}

.input-block {
    &.col-4:nth-child(3n+2) {
        text-align: center;
        display: flex;
        justify-content: center;
    }
    &.col-4:nth-child(3n+3) {
        text-align: right;
        display: flex;
        justify-content: flex-end;
    }
    &.col-6:nth-child(2n+1) {
        display: flex;
        justify-content: flex-end;
    }
    input,
    select,
    textarea {
        border: 1px solid #ccc;
        border-radius: 4px;
        width: 100%;
        height: 2.55em;
        padding-left: 10px;
        padding-right: 10px;
    }
    textarea {
        padding-top: 5px;
        padding-bottom: 5px;
        height: 5rem;
    }
    select::-ms-expand {
        display: none;
    }
    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        /* Add some styling */
        display: block;
        width: 100%;
        background-color: #ffffff;
        background-image: none;
        -ms-word-break: normal;
        word-break: normal;
    }
    .group {
        position: relative;
        margin-top: 25px;
        width: calc(100% - 20px);
    }
    .group.full-width {
        width: 100%;
    }
    .group.select:after {
        content: "\F140";
        font: normal normal normal 24px/1 "Material Design Icons";
        color: #999999;
        right: 11px;
        top: 6px;
        padding: 2px 0px 0px 8px;
        position: absolute;
        pointer-events: none;
    }
    input:focus {
        outline: none;
    }
    /* LABEL ======================================= */
    label {
        color: #999;
        font-size: 1em;
        font-weight: normal;
        position: absolute;
        pointer-events: none;
        left: 10px;
        top: 10px;
        transition: 0.2s ease all;
        -moz-transition: 0.2s ease all;
        -webkit-transition: 0.2s ease all;
    }
    /* active state */
    input:focus,
    input.active,
    select:focus,
    select.active,
    textarea:focus,
    textarea.active {
        border-color: #5264AE;
    }
    input[disabled]:focus,
    input[disabled].active,
    select[disabled]:focus,
    select[disabled].active,
    textarea[disabled]:focus,
    textarea[disabled].active {
        // border-color: transparent;
        background-color: transparent;
    }
    // input[disabled]:focus~label,
    // input[disabled].active~label,
    // select[disabled]:focus~label,
    // select[disabled].active~label,
    // textarea[disabled]:focus~label,
    // textarea[disabled].active~label {
    //     background-color: transparent;
    //     padding-left: 0;
    // }
    input:focus~label,
    input.active~label,
    select:focus~label,
    select.active~label,
    textarea:focus~label,
    textarea.active~label {
        top: -12px;
        font-size: 14px;
        color: #5264AE;
        background-color: #fff;
        padding: 0 5px;
    }
    /* HIGHLIGHTER ================================== */
    .highlight {
        position: absolute;
        height: 60%;
        width: 100px;
        top: 25%;
        left: 0;
        pointer-events: none;
        opacity: 0.5;
        display: none;
    }
    [required]~label::after {
        content: ' *';
        color: red;
    }
    .error{
        color: #f00;
        font-size: 0.875em;
        text-align: left;
        float: left;
    }
    .mdi-help-circle{
        position: absolute;
        top: 7px;
        right: -22px;
    }
    /* active state */
    input:focus~.highlight {
        -webkit-animation: inputHighlighter 0.3s ease;
        -moz-animation: inputHighlighter 0.3s ease;
        animation: inputHighlighter 0.3s ease;
    }
    /* ANIMATIONS ================ */
    @-webkit-keyframes inputHighlighter {
        from {
            background: #5264AE;
        }
        to {
            width: 0;
            background: transparent;
        }
    }
    @-moz-keyframes inputHighlighter {
        from {
            background: #5264AE;
        }
        to {
            width: 0;
            background: transparent;
        }
    }
    @keyframes inputHighlighter {
        from {
            background: #5264AE;
        }
        to {
            width: 0;
            background: transparent;
        }
    }
    [readonly="readonly"]:not(.datepicker) {
        background-color: #FaFaFa;
    }
    .datepicker.mdi::before {
        position: absolute;
        top: 8px;
        font-size: 20px;
        right: 10px;
        color: #ccc;
    }
}

.customer_add {
    .input-block {
        
        &.col-6:nth-child(2n+1) {
            display: flex;
            justify-content: flex-start;
        }
    }
}

.customer-search {
    margin: -1.5rem -1.7rem 0 -1.7rem;
    padding: 1.5rem 1.7rem;
    background-color: #fff;
    .group {
        position: relative;
        width: 170px;
        border-radius: 4px 0 0 4px;
    }
    .group.select:after {
        content: "\F140";
        font: normal normal normal 24px/1 "Material Design Icons";
        color: #999999;
        right: 8px;
        top: 8px;
        padding: 2px 0px 0px 8px;
        position: absolute;
        pointer-events: none;
    }
    select {
        width: 100%;
        font-size: 1em;
        border: 0 none;
        text-align: right;
        padding: 0 10px;
        border-radius: 4px 0 0 4px;
    }
    .form-control {
        font-size: 1em;
        border: 0 none;
        height: 2.75em;
    }
    .input-group {
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 1em;
    }
    .btn {
        border: 0 none;
        border-radius: 0 4px 4px 0;
    }
    .find {
        color: #128bfc;
    }
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

// select::-ms-expand {
//     display: none;
// }

select::after {
    content: "\F140";
    font: normal normal normal 24px/1 "Material Design Icons";
    color: #999999;
    right: 11px;
    top: 6px;
    padding: 2px 0px 0px 8px;
    position: absolute;
    pointer-events: none;
}

.details-block {
    background-color: #ffffff;
    padding: 1.5rem;
    margin-bottom: 15px;
    border-radius: 4px;
    box-shadow: 0px 1px 5px 0px #bbb;
    .details-block-header {
        margin-right: 0.5rem;
        i {
            color: #9F9F9F;
        }
    }
}
.button-group {
    justify-content: flex-end;
}

.datepicker.dropdown-menu {
    z-index: 10000 !important;
}

fieldset.service_add {
    border-top: 1px solid #ccc;
    margin-top: 1em;
    legend {
        background-color: white;
        color: inherit;
        padding: 5px 5px;
        width: auto;
        font-size: 1em;
        margin-left: 1em;
    }
    .input-block {
        &.col-5:nth-child(3n+3) {
            display: flex;
            justify-content: flex-end;
        }
    }
}

.search .mdi-magnify {
    font-size: 1.25em;
}

.table thead {
    background-color: #15A1F7;
    color: #fff;
}

.display-customer {
    .cust-name-btns {
        padding: 1.5rem 1.7rem;
    }
    .nav-tabs {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
    .tab-content {
        background-color: #fff;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        .details-block {
            background: $content-bg;
        }
        .card {
            box-shadow: none;
        }
        .comments-count {
            cursor: pointer;
        }
    }
    .nav-link {
        color: #444;
    }
    .nav-link.active {
        color: #007bff;
    }
}

table {
    td.details-control {
        color: #007bff;
        i {
            font-size: 1.5em;
        }
    }
    .comments-count {
        cursor: pointer;
    }
    .child-table {
        min-width: 50%;
        border-left: 1px solid #dde4eb;
        border-bottom: 1px solid #dde4eb;
    }
    .child-table thead {
        background-color: #dde4eb;
    }
    .child-table th {
        color: #444;
    }
}

.table-bottom {
    .info-length-wrapper {
        display: flex;
        justify-content: flex-start;
        .dataTables_info {
            padding-top: 0.54em;
            margin-right: 1.5em;
        }
        .dataTables_length {
            padding-top: 6px;
        }
    }
}

.modal{
    .modal-content {
        border: 0 none;
    }
    .modal-header {
        background: $navbar-bg;
        padding: 10px 15px;
        color: #fff;
        .close {
            padding: 0;
            margin: 0;
        }
    }
    .modal-footer {
        padding: 5px 15px;
    }
    .mdi-close::before {
        color: #fff;
    }
}

.filterContainer {
    display: flex;
    justify-content: flex-start;
    justify-items: center;
    flex-direction: row;
    .filter {
        display: inline-block;
        margin-right: 0.5rem;
        i {
            font-size: 1.50em;
            line-height: 1em;
            color: #2196f3;
        }
    }
    .filter-label {
        margin-bottom: 0;
        span {
            display: inline-flex !important;
            width: auto !important;
            margin-right: 0.25rem;
        }
        input, select {
            display: inline-flex !important;
            width: auto !important;
        }
        select{
            padding-top: 3px;
            padding-bottom: 5px;
        }
        select::after{
            content: "\F140";
            font: normal normal normal 24px/1 "Material Design Icons";
            color: #999999;
            right: 11px;
            top: 6px;
            padding: 2px 0px 0px 8px;
            position: absolute;
            pointer-events: none;
        }
    }
}

.select2-container{
    .select2-selection--single{
        height: 2.55rem;
    }
    &.select2-container--default{
        .select2-selection--single{
            .select2-selection__rendered{
                line-height: 2.55rem;
                text-align: left;
            }
        } 
    } 
    .select2-selection__arrow{
        height: 2.3rem !important;
        display: none;
    }
}

#product-list{
    .product-item{
        padding: 15px 0;
        border-radius: 4px;
        box-shadow: 0px 0 10px 1px #dedede;
    }
    .input-block {
        .group{
            margin-top: 0;
        }
    }
}
#logs-table-container{
    #logs-table{
        width: 100% !important;
        max-width: 100% !important;
        table-layout: fixed !important;
        .log-details{
            width: auto !important;
        }
    }
}
.input-group{
    .error{
        color: #f00;
        width: 100%;
        font-size: 0.875em;
        display: block;
    }
}
