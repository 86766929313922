/* Cards */

.card {
    border: none;
    box-shadow: $card-shadow-color;
    .card-body {
        padding: $card-padding-y $card-padding-x;
        +.card-body {
            padding-top: 1rem;
        }
        .card-icon-indicator {
            width: 45px;
            height: 45px;
            font-size: 20px;
            color: $white;
            border-radius: 50px;
            text-align: center;
            line-height: 2.2;
        }
    }
    .card-title {
        font-weight: 400;
        color: $card-title-color;
        margin-bottom: 15px;
        text-transform: capitalize;
        font-family: $type-1;
        font-size: 1.25em;
        i {
            color: #9F9F9F;
        }
    }
    .card-subtitle {
        @extend .text-gray;
        font-family: $type-1;
        margin-top: 0.625rem;
        margin-bottom: 0.625rem;
    }
    .card-description {
        color: $card-description-color;
        margin-bottom: 1.5rem;
        font-family: $type-1;
        font-weight: 400;
        color: $card-description-color;
    }
    &.card-outline-success {
        border: 1px solid theme-color("success");
    }
    &.card-outline-primary {
        border: 1px solid theme-color("primary");
    }
    &.card-outline-warning {
        border: 1px solid theme-color("warning");
    }
    &.card-outline-danger {
        border: 1px solid theme-color("danger");
    }
    &.card-rounded {
        @include border-radius(5px);
    }
    &.card-faded {
        background: #b5b0b2;
        border-color: #b5b0b2;
    }
    &.card-circle-progress {
        color: $white;
        text-align: center;
    }
    &.card-img-holder {
        position: relative;
        .card-img-absolute {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
        }
    }
}

@each $color,
$value in $theme-colors {
    .card-inverse-#{$color} {
        @include card-inverse-variant(rgba(theme-color($color), .2), theme-color-level($color, 1), theme-color-level($color, 3));
    }
}